import {
  IGetMailboxPayload,
  IMassUploadAttachmentPayload,
  IMessage,
  IMessageFilter,
  IUploadAttachmentPayload,
  PromiseAction,
  TableSort
} from '../../shared/interfaces'
import * as MessagesApi from '../../apis/messages.api'

export const GET_MESSAGES_TRIGGERED = 'GET_MESSAGES_TRIGGERED'
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS'
export const GET_MESSAGES_FAILURE = 'GET_MESSAGES_FAILURE'

export function getMessages(
  filter: IMessageFilter,
  sort: TableSort<IMessage> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = MessagesApi.getMessages(filter, sort, page, size)
  return {
    onRequest: GET_MESSAGES_TRIGGERED,
    onSuccess: GET_MESSAGES_SUCCESS,
    onFailure: GET_MESSAGES_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

export const GET_MESSAGE_DETAILS_TRIGGERED = 'GET_MESSAGE_DETAILS_TRIGGERED'
export const GET_MESSAGE_DETAILS_SUCCESS = 'GET_MESSAGE_DETAILS_SUCCESS'
export const GET_MESSAGE_DETAILS_FAILURE = 'GET_MESSAGE_DETAILS_FAILURE'

export function getMessageDetails(id: number): PromiseAction {
  const promise = MessagesApi.getMessageDetails(id)
  return {
    onRequest: GET_MESSAGE_DETAILS_TRIGGERED,
    onSuccess: GET_MESSAGE_DETAILS_SUCCESS,
    onFailure: GET_MESSAGE_DETAILS_FAILURE,
    promise
  }
}

export const GET_XML_ATTACHMENT_TRIGGERED = 'GET_XML_ATTACHMENT_TRIGGERED'
export const GET_XML_ATTACHMENT_SUCCESS = 'GET_XML_ATTACHMENT_SUCCESS'
export const GET_XML_ATTACHMENT_FAILURE = 'GET_XML_ATTACHMENT_FAILURE'

export function getXmlAttachment(id: number): PromiseAction {
  const promise = MessagesApi.getXmlAttachment(id)
  return {
    onRequest: GET_XML_ATTACHMENT_TRIGGERED,
    onSuccess: GET_XML_ATTACHMENT_SUCCESS,
    onFailure: GET_XML_ATTACHMENT_FAILURE,
    promise
  }
}

export const GET_MAILBOX_TRIGGERED = 'GET_MAILBOX_TRIGGERED'
export const GET_MAILBOX_SUCCESS = 'GET_MAILBOX_SUCCESS'
export const GET_MAILBOX_FAILURE = 'GET_MAILBOX_FAILURE'

export function getMailbox(payload?: IGetMailboxPayload): PromiseAction {
  const promise = MessagesApi.getMailbox(payload)
  return {
    onRequest: GET_MAILBOX_TRIGGERED,
    onSuccess: GET_MAILBOX_SUCCESS,
    onFailure: GET_MAILBOX_FAILURE,
    promise
  }
}

export const UPLOAD_XML_ATTACHMENT_TRIGGERED = 'UPLOAD_XML_ATTACHMENT_TRIGGERED'
export const UPLOAD_XML_ATTACHMENT_SUCCESS = 'UPLOAD_XML_ATTACHMENT_SUCCESS'
export const UPLOAD_XML_ATTACHMENT_FAILURE = 'UPLOAD_XML_ATTACHMENT_FAILURE'

export function uploadAttachment(payload: IUploadAttachmentPayload): PromiseAction {
  const promise = MessagesApi.uploadAttachment(payload)
  return {
    onRequest: UPLOAD_XML_ATTACHMENT_TRIGGERED,
    onSuccess: UPLOAD_XML_ATTACHMENT_SUCCESS,
    onFailure: UPLOAD_XML_ATTACHMENT_FAILURE,
    promise
  }
}

export const MASS_UPLOAD_XML_ATTACHMENT_TRIGGERED = 'MASS_UPLOAD_XML_ATTACHMENT_TRIGGERED'
export const MASS_UPLOAD_XML_ATTACHMENT_SUCCESS = 'MASS_UPLOAD_XML_ATTACHMENT_SUCCESS'
export const MASS_UPLOAD_XML_ATTACHMENT_FAILURE = 'MASS_UPLOAD_XML_ATTACHMENT_FAILURE'

export function massUploadAttachment(payload: IMassUploadAttachmentPayload[]): PromiseAction {
  const promise = MessagesApi.massUploadAttachment(payload)
  return {
    onRequest: MASS_UPLOAD_XML_ATTACHMENT_TRIGGERED,
    onSuccess: MASS_UPLOAD_XML_ATTACHMENT_SUCCESS,
    onFailure: MASS_UPLOAD_XML_ATTACHMENT_FAILURE,
    promise
  }
}

import {
  IPaginationResponse,
  IPaymentInvoiceGas,
  IPaymentInvoicesGasFilter,
  IPaymentInvoiceGasDetails,
  TableSort
} from 'src/shared/interfaces'
import moment from 'moment'
import { DATE_FORMAT_DB } from 'src/shared/constants'

import Http from './api'
import { generatePaginationPayload } from '../lib/paginationPayload'

export const getAllPaymentInvoicesGas = (
  filter: IPaymentInvoicesGasFilter,
  sort: TableSort<IPaymentInvoiceGas> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {
      'payment_invoice_gas.status': filter.status,
      date_to: [
        filter.date_from ? moment(filter.date_from).format(DATE_FORMAT_DB) : undefined,
        filter.date_to ? moment(filter.date_to).format(DATE_FORMAT_DB) : undefined
      ],
      created_at: [
        filter.created_at_from ? moment(filter.created_at_from).format(DATE_FORMAT_DB) : undefined,
        filter.created_at_to ? moment(filter.created_at_to).format(DATE_FORMAT_DB) : undefined
      ]
    },
    searchBy: ['customer', 'pod'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IPaymentInvoiceGas>>('/payment-invoices-gas', payload)
}

export const getPaymentInvoiceGasDetail = (id: number) => {
  return Http.get<IPaymentInvoiceGasDetails>(`/payment-invoices-gas/${id}`)
}

export const generatePaymentInvoiceGas = () => {
  return Http.post<unknown>(`/payment-invoices-gas/generate`)
}

export const exportPaymentInvoicesGas = (filter: IPaymentInvoicesGasFilter) => {
  const payload = generatePaginationPayload({
    filter: {
      'payment_invoice_gas.status': filter.status,
      date_to: [
        filter.date_from ? moment(filter.date_from).format(DATE_FORMAT_DB) : undefined,
        filter.date_to ? moment(filter.date_to).format(DATE_FORMAT_DB) : undefined
      ]
    },
    searchBy: ['customer', 'pod'],
    search: filter.search,
    sort: undefined,
    page: 1,
    size: 10
  })
  return Http.getFile<unknown>(`/payment-invoices-gas/xls/export?filter=${JSON.stringify(payload)}`)
}

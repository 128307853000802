import React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge } from '@otion-core/sandy'

import { CommodityTypeEnum } from 'src/shared/interfaces'

interface CommodityTypeProps {
  value?: string
}

const CommodityType = (props: CommodityTypeProps) => {
  const { t } = useTranslation()
  if (!props.value) return null

  let color

  switch (props.value) {
    case CommodityTypeEnum.ELECTRICITY:
      color = '#b6f573'
      break
    case CommodityTypeEnum.GAS:
      color = '#bbece8'
      break
  }

  return (
    <Badge style={{ background: color, fontWeight: '500' }} color={color}>
      {t(`COMMODITY_TYPE.${props.value}` as never)}
    </Badge>
  )
}

CommodityType.displayName = 'CommodityType'
export default CommodityType

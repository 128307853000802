export const DATE_FORMAT_COMMON = 'YYYY-MM-DD'
export const DATE_FORMAT_SHORT = 'DD.MM.YYYY'
export const DATE_FORMAT_TIME = 'DD.MM.YYYY HH:mm:ss'
export const DATE_FORMAT_TIME_NO_SEC = 'DD.MM.YYYY HH:mm'
export const DATE_FORMAT_MESSAGE = 'YYYYMMDDHHmmss'
export const DATE_FORMAT_DB = 'YYYY-MM-DDT00:00:00'

// eslint-disable-next-line no-shadow
export enum ExportTypeEnum {
  BILLS_INCOME = 'BILLS_INCOME',
  BILLS_EXPENSE = 'BILLS_EXPENSE',
  BANK_ACCOUNTS = 'BANK_ACCOUNTS',
  PROFORMA_INVOICES = 'PROFORMA_INVOICES',
  PAYMENT_INVOICES = 'PAYMENT_INVOICES',
  INVOICES = 'INVOICES',
  EICS = 'EICS',
  PODS = 'PODS'
}

import moment from 'moment'
import {
  IPaginationResponse,
  IInvoicesGasFilter,
  TableSort,
  IPostGenerateInvoicesGas,
  IInvoiceGas
} from 'src/shared/interfaces'

import Http from './api'
import { generatePaginationPayload } from '../lib/paginationPayload'
import { DATE_FORMAT_DB } from '../shared/constants'

export const getAllInvoicesGas = (
  filter: IInvoicesGasFilter,
  sort: TableSort<IInvoiceGas> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {
      delivery_period_start: [
        filter.delivery_period_start_from
          ? moment(filter.delivery_period_start_from).format(DATE_FORMAT_DB)
          : undefined,
        filter.delivery_period_start_to ? moment(filter.delivery_period_start_to).format(DATE_FORMAT_DB) : undefined
      ],
      type_of_invoice: filter.type_of_invoice,
      status: filter.status
    },
    searchBy: ['customer', 'variable_symbol', 'company_number'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IInvoiceGas>>('/invoices-gas', payload)
}

export const generateProformaInvoicesGas = (payload: IPostGenerateInvoicesGas) => {
  return Http.post<{ count: number }>('/invoices-gas/generate-proforma-invoices', payload)
}

export const generatePaymentInvoicesGas = (payload: IPostGenerateInvoicesGas) => {
  return Http.post<{ count: number }>('/invoices-gas/generate-payment-invoices', payload)
}

export const getInvoiceGasDetails = (id: number) => {
  return Http.get<IInvoiceGas>(`/invoices-gas/${id}`)
}

export const updateInvoiceGasDetails = (id: number, payload: Partial<IInvoiceGas>) => {
  return Http.patch<IInvoiceGas>(`/invoices-gas/${id}`, payload)
}

export const sendInvoiceGas = (id: number) => {
  return Http.post<unknown>(`/invoices-gas/${id}/send-invoice`)
}

export const forceIssueInvoiceGas = (id: number) => {
  return Http.post<unknown>(`/invoices-gas/${id}/force-issue`)
}

export const cancelInvoiceGas = (id: number) => {
  return Http.post<IInvoiceGas>(`/invoices-gas/${id}/cancel-invoice`)
}

export const regenerateInvoiceGas = (id: number) => {
  return Http.post<IInvoiceGas>(`/invoices-gas/${id}/reload-customer-info`)
}

export const getPdfInvoiceGasPreview = (id: number) => {
  return Http.get<unknown>(`/pdf/invoice-gas/${id}?preview=true`, { responseType: 'blob' })
}

export const getPdfInvoiceGas = (id: number) => {
  return Http.getFile<unknown>(`/pdf/invoice-gas/${id}`)
}

export const exportInvoicesGas = (filter: IInvoicesGasFilter) => {
  const payload = generatePaginationPayload({
    filter: {
      delivery_period_start: [
        filter.delivery_period_start_from
          ? moment(filter.delivery_period_start_from).format(DATE_FORMAT_DB)
          : undefined,
        filter.delivery_period_start_to ? moment(filter.delivery_period_start_to).format(DATE_FORMAT_DB) : undefined
      ],
      type_of_invoice: filter.type_of_invoice,
      status: filter.status
    },
    searchBy: ['customer', 'variable_symbol', 'company_number'],
    search: filter.search,
    sort: undefined,
    page: 1,
    size: 10
  })
  return Http.getFile<unknown>(`/invoices-gas/xls/export?filter=${JSON.stringify(payload)}`)
}

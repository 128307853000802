import { combineReducers } from 'redux'

import authReducer from './auth.reducer'
import actionsReducer from './actions.reducer'
import clientsReducer from './clients.reducer'
import contractsReducer from './contracts.reducer'
import consumptionsReducer from './consumptions.reducer'
import customersReducer from './customers.reducer'
import dashboardsReducer from './dashboards.reducer'
import eicReducer from './eic.reducer'
import grossCalorificValueReducer from './grossCalorificValue.reducer'
import invoicesReducer from './invoices.reducer'
import invoicesGasReducer from './invoicesGas.reducer'
import messagesReducer from './messages.reducer'
import priceListReducer from './priceList.reducer'
import paymentInvoicesReducer from './paymentInvoices.reducer'
import paymentInvoicesGasReducer from './paymentInvoicesGas.reducer'
import proformaInvoicesReducer from './proformaInvoices.reducer'
import proformaInvoicesGasReducer from './proformaInvoicesGas.reducer'
import financialReducer from './financial.reducer'
import spotPricesReducer from './spotPrices.reducer'
import warningsReducer from './warnings.reducer'
import usersReducer from './users.reducer'
import tradingReducer from './trading.reducer'
import tradersReducer from './traders.reducer'
import podReducer from './pod.reducer'
import meterValuesReducer from './meterValues.reducer'
import vatRatesReducer from './vatRates.reducer'

export default combineReducers({
  authReducer,
  actionsReducer,
  clientsReducer,
  consumptionsReducer,
  contractsReducer,
  customersReducer,
  dashboardsReducer,
  eicReducer,
  grossCalorificValueReducer,
  invoicesReducer,
  invoicesGasReducer,
  messagesReducer,
  priceListReducer,
  paymentInvoicesReducer,
  paymentInvoicesGasReducer,
  proformaInvoicesReducer,
  proformaInvoicesGasReducer,
  financialReducer,
  spotPricesReducer,
  warningsReducer,
  usersReducer,
  tradingReducer,
  tradersReducer,
  podReducer,
  meterValuesReducer,
  vatRatesReducer
})

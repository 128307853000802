import axios, { AxiosRequestConfig, AxiosRequestHeaders, Method } from 'axios'
import { saveAs } from 'file-saver'

import { IErrorResponse } from '../shared/interfaces'
import { getToken } from '../utils'

const API_SERVER = process.env.REACT_APP_API_SERVER
const http = axios.create({ baseURL: `${API_SERVER}/` })

const request = <ResponseType>(method: Method, url: string, options: AxiosRequestConfig) => {
  const accessToken = getToken()
  return http
    .request({
      ...(options?.params || {}),
      ...options,
      method,
      url,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => httpResponseHandler<ResponseType>(response))
    .catch(error => httpErrorHandler<ResponseType>(error))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const httpResponseHandler = <ResponseType>(response: any): ResponseType => {
  const disposition = response.headers?.['content-disposition']
  if (disposition) {
    let fileName = 'file'
    if (disposition?.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '')
      }
      saveAs(response.data, fileName)
    }
  }
  sessionStorage.removeItem('encare_401_redirected')
  return response
}

const httpErrorHandler = <ResponseType>(err: IErrorResponse): ResponseType => {
  const response = err?.response
  if (response?.status === 401) {
    const alreadyRedirected = sessionStorage.getItem('encare_401_redirected')
    if (!alreadyRedirected) {
      sessionStorage.setItem('encare_401_redirected', 'true')
      window.location.href = '/'
      return null as any
    }
  }

  if (response === undefined) {
    console.log(err)
    return null as any
  }

  const data = response?.data
  if (!data) {
    window.location.href = '/'
    return null as any
  }

  return {
    ...data,
    message: data?.message || 'Network Error!'
  } as any // eslint-disable-line @typescript-eslint/no-explicit-any
}

const Http = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get<ResponseType = any>(
    url: string,
    params: unknown = {},
    signal?: AbortSignal,
    headers = {} as AxiosRequestHeaders
  ) {
    return request<ResponseType>('GET', url, { params, signal, headers })
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFile<ResponseType = any>(url: string, params = {}, signal?: AbortSignal, headers = {} as AxiosRequestHeaders) {
    return request<ResponseType>('GET', url, { params, signal, headers, responseType: 'blob' })
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post<ResponseType = any>(url: string, body: unknown = {}, signal?: AbortSignal, headers = {} as AxiosRequestHeaders) {
    return request<ResponseType>('POST', url, { data: body, signal, headers })
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put<ResponseType = any>(url: string, body: unknown = {}, signal?: AbortSignal, headers = {} as AxiosRequestHeaders) {
    return request<ResponseType>('PUT', url, { data: body, signal, headers })
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patch<ResponseType = any>(
    url: string,
    body: unknown = {},
    signal?: AbortSignal,
    headers = {} as AxiosRequestHeaders
  ) {
    return request<ResponseType>('PATCH', url, { data: body, signal, headers })
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete<ResponseType = any>(
    url: string,
    body: unknown = {},
    signal?: AbortSignal,
    headers = {} as AxiosRequestHeaders
  ) {
    return request<ResponseType>('DELETE', url, { data: body, signal, headers })
  }
}

export default Http

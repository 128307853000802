import { AnyAction } from 'redux'

import { IGrossCalorificValueState } from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IGrossCalorificValueState = {
  grossCalorificValue: []
}

const grossCalorificValueReducer = (state = initialState, action: AnyAction): IGrossCalorificValueState => {
  switch (action.type) {
    case actionTypes.GET_GCV_TRIGGERED: {
      return { ...state, grossCalorificValue: initialState.grossCalorificValue }
    }
    case actionTypes.GET_GCV_SUCCESS: {
      return { ...state, grossCalorificValue: action.response?.data }
    }

    default:
      return state
  }
}

export default grossCalorificValueReducer

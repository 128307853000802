import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@otion-core/sandy'

import { ColorDot } from 'src/components/commonStyled'
import { ContractStatusEnum } from 'src/shared/interfaces'

interface CustomerStatusProps {
  value?: number | string
}

const ContractStatus = (props: CustomerStatusProps) => {
  const { t } = useTranslation()

  if (!props.value) return null

  let color
  let name

  switch (Number(props.value)) {
    case ContractStatusEnum.NEW:
      name = 'NEW'
      color = '#e6e6e6'
      break
    case ContractStatusEnum.WAITING_FOR_DELIVERY:
      name = 'WAITING_FOR_DELIVERY'
      color = '#f3d163'
      break
    case ContractStatusEnum.ACTIVE_DELIVERY:
      name = 'ACTIVE_DELIVERY'
      color = '#8abd25'
      break
    case ContractStatusEnum.TERMINATED_DELIVERY:
      name = 'TERMINATED_DELIVERY'
      color = '#ff3b30'
      break
    case ContractStatusEnum.CONTRACT_CANCELED:
      name = 'CONTRACT_CANCELED'
      color = '#ff3b30'
      break
    case ContractStatusEnum.DRAFT:
      name = 'DRAFT'
      color = '#e6e6e6'
      break
  }

  return (
    <Container as='span' style={{ display: 'inline-flex' }} alignItems='center'>
      <ColorDot color={color} />
      <span>{t(`CONTRACT_STATUS.${name}` as never)}</span>
    </Container>
  )
}

ContractStatus.displayName = 'ContractStatus'
export default ContractStatus

import * as GrossCalorificValueApi from '../../apis/grossCalorificValue.api'
import { IGetGrossCalorificValuePayload, PromiseAction } from '../../shared/interfaces'

// Get GCV
export const GET_GCV_TRIGGERED = 'GET_GCV_TRIGGERED'
export const GET_GCV_SUCCESS = 'GET_GCV_SUCCESS'
export const GET_GCV_FAILURE = 'GET_GCV_FAILURE'

export function getGrossCalorificValue(payload: IGetGrossCalorificValuePayload): PromiseAction {
  const promise = GrossCalorificValueApi.getGrossCalorificValue(payload)
  return {
    onRequest: GET_GCV_TRIGGERED,
    onSuccess: GET_GCV_SUCCESS,
    onFailure: GET_GCV_FAILURE,
    promise
  }
}

import { AnyAction } from 'redux'

import {
  IPaymentInvoiceGasDetails,
  IPaginationResponse,
  IPaymentInvoiceGas,
  IPaymentInvoicesGasState
} from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IPaymentInvoicesGasState = {
  list: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  details: undefined
}

const paymentInvoicesGasReducer = (state = initialState, action: AnyAction): IPaymentInvoicesGasState => {
  switch (action.type) {
    case actionTypes.GET_ALL_PAYMENT_INVOICES_GAS_SUCCESS: {
      const response: IPaginationResponse<IPaymentInvoiceGas> = action.response?.data
      if (!response) return state
      const totalCount = Object.keys(action.payload.filter).length ? state.list.totalCount : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        list: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_PAYMENT_INVOICE_GAS_DETAIL_SUCCESS: {
      const details: IPaymentInvoiceGasDetails = action.response?.data
      return { ...state, details }
    }

    default:
      return state
  }
}

export default paymentInvoicesGasReducer

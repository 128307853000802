/* eslint-disable max-lines */
import {
  TableSort,
  PromiseAction,
  IContract,
  IContractsFilter,
  IContractDetails,
  ICreateContractPayload,
  IPostInvoiceGroupsPayload,
  ICreateContractAmendmentPayload,
  IContractDeliveryPointsPayload,
  ISetTerminationDateContractPayload,
  IUpdateTraderPayload,
  IUploadContractFile,
  ICreateContractDraftPayload,
  CommodityTypeEnum,
  IContractDraftDeliveryPointsPayload
} from '../../shared/interfaces'
import * as ContractsApi from '../../apis/contracts.api'

export const GET_CONTRACTS_TRIGGERED = 'GET_CONTRACTS_TRIGGERED'
export const GET_CONTRACTS_SUCCESS = 'GET_CONTRACTS_SUCCESS'
export const GET_CONTRACTS_FAILURE = 'GET_CONTRACTS_FAILURE'

export const getContracts =
  (commodityType: CommodityTypeEnum) =>
  (filter: IContractsFilter, sort: TableSort<IContract> | undefined, page: number, size = 10): PromiseAction => {
    const filterWithCommodityType = { ...filter, commodity_type: commodityType }
    const promise = ContractsApi.getAllContracts(filterWithCommodityType, sort, page, size)
    return {
      onRequest: GET_CONTRACTS_TRIGGERED,
      onSuccess: GET_CONTRACTS_SUCCESS,
      onFailure: GET_CONTRACTS_FAILURE,
      promise,
      payload: { filter, sort, page, size }
    }
  }

export const GET_CONTRACT_DETAIL_TRIGGERED = 'GET_CONTRACT_DETAIL_TRIGGERED'
export const GET_CONTRACT_DETAIL_SUCCESS = 'GET_CONTRACT_DETAIL_SUCCESS'
export const GET_CONTRACT_DETAIL_FAILURE = 'GET_CONTRACT_DETAIL_FAILURE'

export function getContractDetail(contractId: number | string, amendmentId?: number): PromiseAction {
  const promise = ContractsApi.getContractDetail(contractId, amendmentId)
  return {
    onRequest: GET_CONTRACT_DETAIL_TRIGGERED,
    onSuccess: GET_CONTRACT_DETAIL_SUCCESS,
    onFailure: GET_CONTRACT_DETAIL_FAILURE,
    promise,
    payload: { contractId }
  }
}

export const UPDATE_CONTRACT_DETAIL_TRIGGERED = 'UPDATE_CONTRACT_DETAIL_TRIGGERED'
export const UPDATE_CONTRACT_DETAIL_SUCCESS = 'UPDATE_CONTRACT_DETAIL_SUCCESS'
export const UPDATE_CONTRACT_DETAIL_FAILURE = 'UPDATE_CONTRACT_DETAIL_FAILURE'

export function updateContractDetail(contractId: number, values: Partial<IContractDetails>): PromiseAction {
  const promise = ContractsApi.updateContractDetail(contractId, values)
  return {
    onRequest: UPDATE_CONTRACT_DETAIL_TRIGGERED,
    onSuccess: UPDATE_CONTRACT_DETAIL_SUCCESS,
    onFailure: UPDATE_CONTRACT_DETAIL_FAILURE,
    promise,
    payload: { contractId, values }
  }
}

export const PROCESS_CONTRACT_TRIGGERED = 'PROCESS_CONTRACT_TRIGGERED'
export const PROCESS_CONTRACT_SUCCESS = 'PROCESS_CONTRACT_SUCCESS'
export const PROCESS_CONTRACT_FAILURE = 'PROCESS_CONTRACT_FAILURE'

export function processContract(contractId: number): PromiseAction {
  const promise = ContractsApi.processContract(contractId)
  return {
    onRequest: PROCESS_CONTRACT_TRIGGERED,
    onSuccess: PROCESS_CONTRACT_SUCCESS,
    onFailure: PROCESS_CONTRACT_FAILURE,
    promise,
    payload: { contractId }
  }
}

export const UNPROCESS_CONTRACT_TRIGGERED = 'UNPROCESS_CONTRACT_TRIGGERED'
export const UNPROCESS_CONTRACT_SUCCESS = 'UNPROCESS_CONTRACT_SUCCESS'
export const UNPROCESS_CONTRACT_FAILURE = 'UNPROCESS_CONTRACT_FAILURE'

export function unprocessContract(contractId: number): PromiseAction {
  const promise = ContractsApi.unprocessContract(contractId)
  return {
    onRequest: UNPROCESS_CONTRACT_TRIGGERED,
    onSuccess: UNPROCESS_CONTRACT_SUCCESS,
    onFailure: UNPROCESS_CONTRACT_FAILURE,
    promise,
    payload: { contractId }
  }
}

export const CREATE_CONTRACT_TRIGGERED = 'CREATE_CONTRACT_TRIGGERED'
export const CREATE_CONTRACT_SUCCESS = 'CREATE_CONTRACT_SUCCESS'
export const CREATE_CONTRACT_FAILURE = 'CREATE_CONTRACT_FAILURE'

export function createContract(values: ICreateContractPayload): PromiseAction {
  const promise = ContractsApi.createContract(values)
  return {
    onRequest: CREATE_CONTRACT_TRIGGERED,
    onSuccess: CREATE_CONTRACT_SUCCESS,
    onFailure: CREATE_CONTRACT_FAILURE,
    promise,
    payload: { values }
  }
}

export const CREATE_CONTRACT_AMENDMENT_TRIGGERED = 'CREATE_CONTRACT_AMENDMENT_TRIGGERED'
export const CREATE_CONTRACT_AMENDMENT_SUCCESS = 'CREATE_CONTRACT_AMENDMENT_SUCCESS'
export const CREATE_CONTRACT_AMENDMENT_FAILURE = 'CREATE_CONTRACT_AMENDMENT_FAILURE'

export function createContractAmendment(id: number, values: ICreateContractAmendmentPayload): PromiseAction {
  const promise = ContractsApi.createContractAmendment(id, values)
  return {
    onRequest: CREATE_CONTRACT_AMENDMENT_TRIGGERED,
    onSuccess: CREATE_CONTRACT_AMENDMENT_SUCCESS,
    onFailure: CREATE_CONTRACT_AMENDMENT_FAILURE,
    promise,
    payload: { values }
  }
}

export const DELETE_CONTRACT_AMENDMENT_TRIGGERED = 'DELETE_CONTRACT_AMENDMENT_TRIGGERED'
export const DELETE_CONTRACT_AMENDMENT_SUCCESS = 'DELETE_CONTRACT_AMENDMENT_SUCCESS'
export const DELETE_CONTRACT_AMENDMENT_FAILURE = 'DELETE_CONTRACT_AMENDMENT_FAILURE'

export function deleteContractAmendment(contractId: number): PromiseAction {
  const promise = ContractsApi.deleteContractAmendment(contractId)
  return {
    onRequest: DELETE_CONTRACT_AMENDMENT_TRIGGERED,
    onSuccess: DELETE_CONTRACT_AMENDMENT_SUCCESS,
    onFailure: DELETE_CONTRACT_AMENDMENT_FAILURE,
    promise
  }
}

export const GET_CONTRACTS_STATUSES_TRIGGERED = 'GET_CONTRACTS_STATUSES_TRIGGERED'
export const GET_CONTRACTS_STATUSES_SUCCESS = 'GET_CONTRACTS_STATUSES_SUCCESS'
export const GET_CONTRACTS_STATUSES_FAILURE = 'GET_CONTRACTS_STATUSES_FAILURE'

export const getContractsStatuses = () => {
  const promise = ContractsApi.getContractsStatuses()
  return {
    onRequest: GET_CONTRACTS_STATUSES_TRIGGERED,
    onSuccess: GET_CONTRACTS_STATUSES_SUCCESS,
    onFailure: GET_CONTRACTS_STATUSES_FAILURE,
    promise
  }
}

export const GET_CONTRACT_INVOICE_GROUPS_TRIGGERED = 'GET_CONTRACT_INVOICE_GROUPS_TRIGGERED'
export const GET_CONTRACT_INVOICE_GROUPS_SUCCESS = 'GET_CONTRACT_INVOICE_GROUPS_SUCCESS'
export const GET_CONTRACT_INVOICE_GROUPS_FAILURE = 'GET_CONTRACT_INVOICE_GROUPS_FAILURE'

export function getContractInvoiceGroups(contractId: number): PromiseAction {
  const promise = ContractsApi.getContractInvoiceGroups(contractId)
  return {
    onRequest: GET_CONTRACT_INVOICE_GROUPS_TRIGGERED,
    onSuccess: GET_CONTRACT_INVOICE_GROUPS_SUCCESS,
    onFailure: GET_CONTRACT_INVOICE_GROUPS_FAILURE,
    promise,
    payload: { contractId }
  }
}

export const POST_CONTRACT_INVOICE_GROUPS_TRIGGERED = 'POST_CONTRACT_INVOICE_GROUPS_TRIGGERED'
export const POST_CONTRACT_INVOICE_GROUPS_SUCCESS = 'POST_CONTRACT_INVOICE_GROUPS_SUCCESS'
export const POST_CONTRACT_INVOICE_GROUPS_FAILURE = 'POST_CONTRACT_INVOICE_GROUPS_FAILURE'

export function postContractInvoiceGroups(contractId: number, payload: IPostInvoiceGroupsPayload): PromiseAction {
  const promise = ContractsApi.postContractInvoiceGroups(contractId, payload)
  return {
    onRequest: POST_CONTRACT_INVOICE_GROUPS_TRIGGERED,
    onSuccess: POST_CONTRACT_INVOICE_GROUPS_SUCCESS,
    onFailure: POST_CONTRACT_INVOICE_GROUPS_FAILURE,
    promise,
    payload: { contractId, payload }
  }
}

export const POST_CONTRACT_DELIVERY_POINTS_TRIGGERED = 'POST_CONTRACT_DELIVERY_POINTS_TRIGGERED'
export const POST_CONTRACT_DELIVERY_POINTS_SUCCESS = 'POST_CONTRACT_DELIVERY_POINTS_SUCCESS'
export const POST_CONTRACT_DELIVERY_POINTS_FAILURE = 'POST_CONTRACT_DELIVERY_POINTS_FAILURE'

export function postContractDeliveryPoints(contractId: number, payload: IContractDeliveryPointsPayload): PromiseAction {
  const promise = ContractsApi.postContractDeliveryPoints(contractId, payload)
  return {
    onRequest: POST_CONTRACT_DELIVERY_POINTS_TRIGGERED,
    onSuccess: POST_CONTRACT_DELIVERY_POINTS_SUCCESS,
    onFailure: POST_CONTRACT_DELIVERY_POINTS_FAILURE,
    promise,
    payload: { contractId, payload }
  }
}

export const SET_TERMINATION_DATE_CONTRACT_TRIGGERED = 'SET_TERMINATION_DATE_CONTRACT_TRIGGERED'
export const SET_TERMINATION_DATE_CONTRACT_SUCCESS = 'SET_TERMINATION_DATE_CONTRACT_SUCCESS'
export const SET_TERMINATION_DATE_CONTRACT_FAILURE = 'SET_TERMINATION_DATE_CONTRACT_FAILURE'

export function setTerminationDateContract(
  contractId: number,
  values: ISetTerminationDateContractPayload
): PromiseAction {
  const promise = ContractsApi.setTerminationDateContract(contractId, values)
  return {
    onRequest: SET_TERMINATION_DATE_CONTRACT_TRIGGERED,
    onSuccess: SET_TERMINATION_DATE_CONTRACT_SUCCESS,
    onFailure: SET_TERMINATION_DATE_CONTRACT_FAILURE,
    promise
  }
}

export const SET_TRADER_ID_TO_CONTRACT_TRIGGERED = 'SET_TRADER_ID_TO_CONTRACT_TRIGGERED'
export const SET_TRADER_ID_TO_CONTRACT_SUCCESS = 'SET_TRADER_ID_TO_CONTRACT_SUCCESS'
export const SET_TRADER_ID_TO_CONTRACT_FAILURE = 'SET_TRADER_ID_TO_CONTRACT_FAILURE'

export function setTraderId(contractId: number, values: IUpdateTraderPayload): PromiseAction {
  const promise = ContractsApi.setTraderId(contractId, values)
  return {
    onRequest: SET_TRADER_ID_TO_CONTRACT_TRIGGERED,
    onSuccess: SET_TRADER_ID_TO_CONTRACT_SUCCESS,
    onFailure: SET_TRADER_ID_TO_CONTRACT_FAILURE,
    promise
  }
}

export const UPLOAD_CONTRACTS_TRIGGERED = 'UPLOAD_CONTRACTS_TRIGGERED'
export const UPLOAD_CONTRACTS_SUCCESS = 'UPLOAD_CONTRACTS_SUCCESS'
export const UPLOAD_CONTRACTS_FAILURE = 'UPLOAD_CONTRACTS_FAILURE'

export function uploadContracts(values: IUploadContractFile, isDraft = false): PromiseAction {
  const promise = ContractsApi.importContracts(values, isDraft)
  return {
    onRequest: UPLOAD_CONTRACTS_TRIGGERED,
    onSuccess: UPLOAD_CONTRACTS_SUCCESS,
    onFailure: UPLOAD_CONTRACTS_FAILURE,
    promise
  }
}

export const GET_CONTRACT_NUMBER_TRIGGERED = 'GET_CONTRACT_NUMBER_TRIGGERED'
export const GET_CONTRACT_NUMBER_SUCCESS = 'GET_CONTRACT_NUMBER_SUCCESS'
export const GET_CONTRACT_NUMBER_FAILURE = 'GET_CONTRACT_NUMBER_FAILURE'

export function getContractNumber(commodityType: CommodityTypeEnum): PromiseAction {
  const promise = ContractsApi.getContractNumber(commodityType)
  return {
    onRequest: GET_CONTRACT_NUMBER_TRIGGERED,
    onSuccess: GET_CONTRACT_NUMBER_SUCCESS,
    onFailure: GET_CONTRACT_NUMBER_FAILURE,
    promise
  }
}

export const GET_DRAFTS_CONTRACTS_TRIGGERED = 'GET_DRAFTS_CONTRACTS_TRIGGERED'
export const GET_DRAFTS_CONTRACTS_SUCCESS = 'GET__DRAFTS_CONTRACTS_SUCCESS'
export const GET_DRAFTS_CONTRACTS_FAILURE = 'GET_DRAFTS_CONTRACTS_FAILURE'

export function getDraftsContracts(
  filter: IContractsFilter,
  sort: TableSort<IContract> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = ContractsApi.getDraftsContracts(filter, sort, page, size)
  return {
    onRequest: GET_DRAFTS_CONTRACTS_TRIGGERED,
    onSuccess: GET_DRAFTS_CONTRACTS_SUCCESS,
    onFailure: GET_DRAFTS_CONTRACTS_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

export const CREATE_DRAFT_CONTRACT_TRIGGERED = 'CREATE_DRAFT_CONTRACT_TRIGGERED'
export const CREATE_DRAFT_CONTRACT_SUCCESS = 'CREATE_DRAFT_CONTRACT_SUCCESS'
export const CREATE_DRAFT_CONTRACT_FAILURE = 'CREATE_DRAFT_CONTRACT_FAILURE'

export function createDraftContract(values: ICreateContractDraftPayload): PromiseAction {
  const promise = ContractsApi.createDraftContract(values)
  return {
    onRequest: CREATE_DRAFT_CONTRACT_TRIGGERED,
    onSuccess: CREATE_DRAFT_CONTRACT_SUCCESS,
    onFailure: CREATE_DRAFT_CONTRACT_FAILURE,
    promise,
    payload: { values }
  }
}

export const SET_APPROVE_CONTRACT_TRIGGERED = 'SET_APPROVE_CONTRACT_TRIGGERED'
export const SET_APPROVE_CONTRACT_SUCCESS = 'SET_APPROVE_CONTRACT_SUCCESS'
export const SET_APPROVE_CONTRACT_FAILURE = 'SET_APPROVE_CONTRACT_FAILURE'

export function setApproveContractDraft(contractId: number): PromiseAction {
  const promise = ContractsApi.setApproveContractDraft(contractId)
  return {
    onRequest: SET_APPROVE_CONTRACT_TRIGGERED,
    onSuccess: SET_APPROVE_CONTRACT_SUCCESS,
    onFailure: SET_APPROVE_CONTRACT_FAILURE,
    promise,
    payload: { contractId }
  }
}

export const DECLINE_CONTRACT_TRIGGERED = 'DECLINE_CONTRACT_TRIGGERED'
export const DECLINE_CONTRACT_SUCCESS = 'DECLINE_CONTRACT_SUCCESS'
export const DECLINE_CONTRACT_FAILURE = 'DECLINE_CONTRACT_FAILURE'

export function declineContractDraft(contractId: number): PromiseAction {
  const promise = ContractsApi.declineContractDraft(contractId)
  return {
    onRequest: DECLINE_CONTRACT_TRIGGERED,
    onSuccess: DECLINE_CONTRACT_SUCCESS,
    onFailure: DECLINE_CONTRACT_FAILURE,
    promise,
    payload: { contractId }
  }
}

export const GENERATE_DOC_CONTRACT_TRIGGERED = 'GENERATE_DOC_CONTRACT_TRIGGERED'
export const GENERATE_DOC_CONTRACT_SUCCESS = 'GENERATE_DOC_CONTRACT_SUCCESS'
export const GENERATE_DOC_CONTRACT_FAILURE = 'GENERATE_DOC_CONTRACT_FAILURE'

export function generateDocContract(contractNumber: string): PromiseAction {
  const promise = ContractsApi.generateDocContract(contractNumber)
  return {
    onRequest: GENERATE_DOC_CONTRACT_TRIGGERED,
    onSuccess: GENERATE_DOC_CONTRACT_SUCCESS,
    onFailure: GENERATE_DOC_CONTRACT_FAILURE,
    promise
  }
}

export const POST_DRAFT_CONTRACT_DELIVERY_POINTS_TRIGGERED = 'POST_DRAFT_CONTRACT_DELIVERY_POINTS_TRIGGERED'
export const POST_DRAFT_CONTRACT_DELIVERY_POINTS_SUCCESS = 'POST_DRAFT_CONTRACT_DELIVERY_POINTS_SUCCESS'
export const POST_DRAFT_CONTRACT_DELIVERY_POINTS_FAILURE = 'POST_DRAFT_CONTRACT_DELIVERY_POINTS_FAILURE'

export function postDraftContractDeliveryPoints(
  contractId: number,
  payload: IContractDraftDeliveryPointsPayload
): PromiseAction {
  const promise = ContractsApi.postContractDraftDeliveryPoints(contractId, payload)
  return {
    onRequest: POST_DRAFT_CONTRACT_DELIVERY_POINTS_TRIGGERED,
    onSuccess: POST_DRAFT_CONTRACT_DELIVERY_POINTS_SUCCESS,
    onFailure: POST_DRAFT_CONTRACT_DELIVERY_POINTS_FAILURE,
    promise,
    payload: { contractId, payload }
  }
}
